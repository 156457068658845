.data-card {
  width: 95%;
  margin: 15px auto;
  border-radius: 5px;
  box-shadow: 2px 2px 7px 0px;
  padding: 8px 3px 12px 3px;
  background: rgba(255, 255, 255, 0.7);
}
.data-card.expanded {
  padding-bottom: 30px;
}
.data-card .header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 5px 1px 30px;
  color: rgb(75, 75, 75);
  align-items: baseline;
}
.data-card .header .additional-info {
  color: darkgrey;
  font-size: 0.9em;
  margin-left: 10px;
}
.data-card .header .from {
  margin-left: 6px;
  color: rgb(95, 95, 95);
  font-weight: bold;
}
.data-card .header .bool-action {
  cursor: pointer;
  margin-left: auto;
  width: 55px;

  text-align: center;
  font-size: 0.7em;
}

.data-card .header .collapse-icon {
  margin-left: auto;
  cursor: pointer;
}

.data-card .notification-subject {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
  margin-left: 4rem;
  font-weight: bold;
  color: darkgrey;
}

.data-card .content {
  --max-lines: 4;
  --line-height: 1.4;

  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);
  margin-top: 0.5rem;
  margin-right: auto;
  overflow: hidden;
}

.data-card .urgency {
  display: flex;
  font-size: 0.8em;
  margin-left: 30px;
}

.data-card .button-action {
  margin-top: -2.5em;
  float: right;
  padding: 30px;
}
