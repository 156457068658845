.info-icon {
  position: relative;
  display: flex;
  align-items: flex-start;
  filter: invert(0.8);
}
.info-icon img:hover {
  cursor: pointer;
  filter: invert(0.2);
}

.tool-tip {
  position: absolute;
  z-index: 500;
  display: flex;
  align-items: flex-start;
  margin-left: 2rem;
  padding: 0.5rem;
  min-width: 200px;
  max-width: max-content;
  background: white;
  border-radius: 0.25rem;
}
.tool-tip .info-title {
  font-weight: bold;
}
.tool-tip p.triangle {
  position: absolute;
  left: -0.5rem;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
}

/* Toast */
.toast-blanket {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.35);
}
.toast-block {
  position: relative;
  width: 75%;
  max-width: 600px;
  min-width: 300px;
  min-height: 150px;
  z-index: 6;
  background: white;
  opacity: 0.95;
  border-radius: 25px;
  top: -30px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
}
.toast-block .toast-header {
  display: flex;
  justify-content: space-between;
}
.toast-header .help {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-size: 0.9rem;
}
.toast-block .info-text {
  margin-top: 1rem;
}
.toast-block .toast-close {
  position: absolute;
  bottom: 1rem;
  right: 1.25rem;
  cursor: pointer;
  text-decoration: underline;
}
