.data-list-container {
  contain: content;
  max-height: 60dvh;

  overflow-y: scroll;
  font-size: 0.9em;
}
.data-list-container .data-list-table {
  max-width: 100%;
}
.data-list-container .data-list-table {
  max-width: 100%;
}
.data-list-container .data-list-table tr td {
  cursor: pointer;
  vertical-align: middle;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
.data-list-table tr.active {
  border-color: darkgray;
  background-color: darkgrey;
}

.data-list-table tr.expanded-row {
  background: #ced4da;
  max-height: 2em;
  font-size: 0.8em;
  color: #6c757d;
  padding: 0;
}
.data-list-table tr.expanded-row button {
  font-size: 0.9em;
  height: 2.5em;
}

.data-list-table
  tr[data-notification="true"]
  td[data-notification="true"]::before {
  /* position: absolute;
  right: 1em; */
  content: "";
  background-color: rgb(247, 99, 124);
  width: 1em;
  height: 1em;
  border-radius: 1em;
  margin-right: 0.25rem;
}

.data-list-table
  tr[data-notification="true"]
  td[data-notification="true"]
  span.notification-marker {
  position: absolute;
  background-color: rgb(226, 105, 125);
  width: 1.5em;
  padding: 0;
  margin-left: 1.25em;
  vertical-align: middle;
  border-radius: 1em;
  text-align: center;
}
