.legal-terms {
  height: 500px;
  overflow-y: scroll;
  padding: 1em;
  background-color: rgba(256, 256, 256, 0.2);
  border: 1px solid grey;
  border-radius: 1em;
}

.full-page-legal-terms {
  height: 80vh;
}

.legal-terms .updated-statement {
  margin-bottom: 1em;
}

.legal-terms section {
  margin-top: 1em;
}

.legal-terms p.introduction,
.legal-terms section p {
  margin-top: 0.5em;
}
