.home-left-section {
  min-width: 250px;
  max-width: min(750px, 90%);
  flex-grow: 1;
}
.home-right-section {
  max-width: 25%;
  flex-grow: 0;
  text-align: center;
}
.calls-to-action {
  display: flex;
  flex-direction: column;
}
.call-to-action {
  margin-top: 3rem;
  text-align: center;
}
.call-to-action button {
  margin-top: 1rem;
}

.mission {
  margin: auto;
  margin-bottom: 2rem;
  max-width: max(650px, 90%);
}

.features {
  min-width: 300px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.feature-list {
  width: 95%;
  margin: auto;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  text-align: center;
}
.feature-list-item {
  width: 45%;
  min-width: 260px;
  margin: auto;
  border: 1px gray solid;
  padding: 0.5em;
  border-radius: 5px;
  background: rgba(250, 250, 250, 0.3);
}
.feature-title {
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid grey;
  max-lines: 2;
}

.solutions {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .calls-to-action {
    flex-direction: row;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    align-items: baseline;
  }

  .home-right-section {
    max-width: 90%;
    margin-bottom: 3em;
  }
}
