.navbar {
  --primary-color: #384777;
  --text-color: lightgrey;
}

.navbar-brand {
  position: relative;
  height: 100px;
}
.nav-logo-img {
  width: 300px;
  overflow: hidden;
  aspect-ratio: 3/1;
  object-fit: cover;
  margin-left: 1rem;
}
.logout {
  position: absolute;
  right: 0px;
  top: 0px;
  align-self: flex-start;
  vertical-align: top;
  color: aliceblue;
}
.minor-brand {
  position: relative;
  margin-left: 10px;
}
.minor-brand img {
  height: 50px;
  aspect-ratio: 3/5;
  border: 2px solid black;
}
.minor-brand img:hover {
  border-color: white;
  filter: invert();
}

/* style the dropdown lists */
.dropdown-menu {
  background-color: var(--primary-color);
  color: var(--text-color);
}
.dropdown-item {
  color: var(--text-color);

  font-size: 1.1em;
  margin-top: 0.5em;
}

/* Style the title of NavDropdown when it's expanded and closed*/
.navbar .dropdown-toggle,
.navbar .dropdown-toggle[aria-expanded="true"] {
  color: var(--text-color);
}
.navbar .dropdown-toggle[aria-expanded="true"] {
  text-decoration: underline;
  text-underline-offset: 5px;
}

/* drop down arrow */
.navbar .dropdown-toggle::after {
  display: none;
}
/* notification circle on main dropdown title */
.navbar .nav-link[data-notification="true"] .dropdown-toggle::after,
.dropdown-item[data-notification="true"]::after {
  content: "";
  display: inline-block;
  width: 8px; /* Adjust size as needed */
  height: 11px; /* Adjust size as needed */
  background-color: pink; /* Example color for the circle */
  color: pink;
  border-radius: 50%;
  margin-left: 5px; /* Adjust spacing as needed */
}
/* notification circle on dropdown item */
.dropdown-item[data-notification="true"]::after {
  content: "";
  display: inline-block;
  width: 8px; /* Adjust size as needed */
  height: 8px; /* Adjust size as needed */
  background-color: pink; /* Example color for the circle */
  color: pink;
  border-radius: 50%;
  margin-left: 5px; /* Adjust spacing as needed */
  vertical-align: super;
}

/* Medium screens */
@media (max-width: 768px) {
  .logout {
    display: none;
  }
}

/* Small screens */
@media (max-width: 575px) {
  .navbar .minor-brand img {
    max-height: 40px;
  }
}
