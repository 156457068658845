.FAQs h1 {
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid grey;
}

.FAQ-list {
  flex-direction: column;
  width: 85%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.05);
}

.FAQ-list-item {
  padding: 1rem;
}
.FAQ-list-item.expanded {
  background: rgba(0, 0, 0, 0.05);
}

.FAQ-list-item:not(:last-child) {
  border-bottom: 2px black solid;
}

.FAQ-question {
  display: flex;
  align-items: baseline;
  padding: 5px;
  user-select: none;
}
.FAQ-question:hover {
  background: rgba(0, 0, 0, 0.05);
}
.FAQ-question::before {
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 1rem;
  content: "Q: ";
}
.FAQ-question::after {
  font-weight: bold;
  font-size: 1.5em;
  content: ">";
  margin-left: auto;
  margin-right: 30px;
  transition: all 200ms;
}
.FAQ-list-item.expanded .FAQ-question::after {
  font-weight: bold;
  font-size: 1.5em;
  margin-left: auto;
  margin-right: 30px;
  transform: rotate(90deg);
  transition: all 400ms;
}

.FAQ-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms;
}
.FAQ-list-item.expanded .FAQ-answer {
  max-height: 400px;
  transition: max-height 400ms;
}
.FAQ-answer::before {
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 1rem;
  content: "A: ";
}
