.message-card {
    --recieved-color: rgba(240, 240, 200, 0.99);
    --sent-color: rgba(200, 200, 240, 0.99);

    margin-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
}

.message-card.sent {
    border: 1px solid white;
    background-color: var(--sent-color);
}
.message-card.recieved {
    border: 1px solid black;
    background-color: var(--recieved-color);
}

.header-info { 
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
}
.header-info > p {
    margin-right: 2rem;
}

.body-container {
    display: flex;
}

.content {
    --max-lines: 3;
    --line-height: 1.4;

    max-height: calc(var(--max-lines) * 1em * var(--line-height));  
    line-height: var(--line-height);
    margin-top: 0.5rem;
    margin-right: auto;
    overflow: hidden;
}

.button {
    margin-left: auto;
    margin-bottom: 1rem;
    width: 100px;
}
