.column-container *,
.column-container *::before,
.column-container *::after {
  margin: 0;
  padding: 0;
}

.column-container {
  /* outline: 1px solid red; */
  position: relative;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
}

.column {
  /* outline: 1px solid orange; */
  margin-top: 20px;
  min-width: 15ch;
  flex-grow: 1;
}

.column-title {
  /* outline: 1px solid blue; */
  font-size: 1.5em;
  margin-bottom: 0.5rem;
}

.report-list-item i::before {
  content: "*";
}

.footnote {
  font-size: 0.75em;
}
