.page-container {
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 3px 4px 10px 0px;
  border-radius: 0.75rem;
  padding: 20px 50px;
  margin: 0 auto 20px;
  margin-left: 20px;
}

.page-title {
  font-size: 1.5em;
  color: var(--heading-color);
  display: inline-block;
}
.section-title {
  font-size: 1.3em;
  color: var(--heading-color);
  display: inline-block;
}
.side-data {
  min-width: 350px;
  max-width: 350px;
  margin: 0 auto 20px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid black;
}

/* FOR THE TABBED LAYOUT */
.page-container.tabbed {
  box-shadow: none;
  border-radius: 0.5rem;
  background: rgb(247, 214, 214);
  border: 1px black solid;
  border-right: 1px black solid;
  border-left: 1px black solid;
  z-index: 1;
}
.tab-headers {
  /* Margin the size of the border */
  margin-bottom: -1px;
}
.tab-headers .tab-header {
  border-left: solid 1px black;
  border-right: solid 1px black;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  border-radius: 1rem 1rem 0rem 0rem;
  z-index: 1;
  background: rgb(230, 202, 202);
}
.tab-headers .tab-header.active {
  border-bottom: 0;
  background: rgb(247, 214, 214);
}
