.flyin-background {
  --animation-time: 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100%;
  animation: fadeIn ease var(--animation-time);
  background-color: black;
  opacity: 0.5;
}
@keyframes fadeIn {
  from {
    right: -100vw;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 0.5;
  }
}

.flyin {
  touch-action: none;
  overflow-y: scroll;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: -1rem;
  padding: 2rem;
  padding-right: 5rem;
  height: 100dvh;
  width: calc(min(80dvh, 80dvw));
  border: 2px darkslateblue inset;
  border-radius: 1rem;
  z-index: 100;
}

.flyin .close {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  top: 0.5rem;
  left: 0.5rem;
}
.flyin .header {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.flyin .input {
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}
.flyin .search {
  display: flex;
  flex-wrap: wrap;
}
.flyin .search-form {
  display: flex;
}
.flyin .searchform:first-of-type(input) {
  flex-shrink: 1;
  min-width: 10rem;
}
.flyin .searchform .submit-button {
  flex-shrink: 2;
}

.flyin .tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}
.flyin .tags .tag {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  height: 2rem;
  border: 1px solid black;
  border-radius: 1rem;
  padding: 3px 5px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  vertical-align: middle;
  text-align: center;
}
.flyin .tags .tag:hover,
.flyin .tags .tag.active {
  background-color: rgba(255, 255, 255, 0.6);
}
.flyin .tags .remove-tags {
  cursor: pointer;
  height: 2rem;
  border-radius: 1rem;
  padding: 3px 5px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  text-decoration: underline;
}

.results {
  margin-top: 1rem;
  max-height: max-content;
}

.results .result {
  border-bottom: 1px solid black;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.results .result:last-child {
  border: none;
  padding-bottom: none;
  margin-bottom: none;
}
.results .result > span {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.75rem;
}
.results .result > span.result-tag:after {
  content: ",";
}
.results .result span.result-tag + span.result-tag + span.result-tag::after {
  content: "";
}
