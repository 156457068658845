.other-control-options {
  display: flex;
  margin-bottom: 20px;
  font-size: 0.8em;
  align-self: flex-end;
}

.eq-type-link {
  cursor: pointer;
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0);
  max-width: 80%;
}
.eq-type-link.active,
.eq-type-link:hover {
  border: gray 2px solid;
}

.filter-statement:hover {
  cursor: pointer;
  text-decoration: underline;
}

.history-log {
  max-height: 600px;
}

.object-data-format {
  text-align: left;
}
.object-data:nth-child(n) {
  border: 1px solid lightgray;
  background: rgba(0, 0, 0, 0.05);
}
.object-data-format .row {
  max-width: 100%;
  margin: auto;
}
.object-data.editable:nth-child(n) {
  padding-left: 2em;
  border: none;
  background: rgba(255, 255, 255, 0.5);
}
/* .object-data.editable:last-child {
  padding-bottom: 0.75em;
} */
.object-data .key-name {
  margin-right: 1em;
  font-weight: bold;
}
.object-data input {
  height: 1.5em;
}

@media (max-width: 987px) {
  .equipment-list-container {
    max-height: 400px;
    overflow-y: scroll;
  }
}

@media (max-width: 1199px) {
  .equipment-list-container {
    max-height: 400px;
    min-width: 100%;
    max-width: 100%;
    font-size: 1em;
    overflow-y: scroll;
  }
}
