.auth-form {
    width:300px;
    padding: 40px;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
    background: #fff;
}
.auth-options {
    font-size: 0.9em;
    margin-top: 15px;
    text-align: center;
}
.auth-message {
    background: var(--primary-color);
    text-align: center;
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;
}