.app-nav {
  margin-bottom: 20px;
  background: var(--primary-color);
  max-height: 110px;
  min-width: 100%;
  z-index: 2;
  font-size: 1.2em;
}
.navbar img {
  min-height: 100%;
  max-height: 110px;
}
.navbar img:hover {
  cursor: pointer;
}

.navbar-collapse.collapse {
  padding: 5px;
}
.navbar-collapse.collapse.show {
  margin-top: 5px;
  background: var(--primary-color);
  padding: 5px;
}

.main-nav-link-container .nav-item {
  width: 100%;
  padding: 2px;
  margin-top: 1.1em;
}
.main-nav-link-container .nav-item:hover {
  background: rgba(256, 256, 256, 0.1);
  border: darkgray 2px solid;
  padding: 0;
}
.main-nav-link-container .nav-item:has(> .nav-link.active) {
  background: rgba(256, 256, 256, 0.1);
  border: darkgray 2px solid;
  padding: 0;
}

/* Small screens */
@media (max-width: 575px) {
  .navbar img {
    max-height: 55px;
  }
}
